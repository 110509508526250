import React, { useEffect } from 'react';
import styled from 'styled-components';
import uuid from 'react-uuid';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import GetHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import PageContainer from '../../common/PageContainer';
import NohoApp from '../../assets/image/nohoSmart/verticalSlider/noho_app.webp';
import NohoServices from '../../assets/image/nohoSmart/verticalSlider/noho_service.webp';

const StyledSliderContainer = styled.div`
	display: block;
	width: 100%;
	@media (min-width: 1281px) {
		display: none;
	}
`;

const StyledSingleSlideWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	transition: background-color 1s ease-in-out;
	width: 100%;
`;

const StyledTextPartWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 40px 25px 50px;
	width: 100%;
	&.slide-text-wrapper-mobile {
		&-0 {
			background-color: #dedede;
		}
		&-1 {
			background-color: ${({ theme }) => theme.colors.creamyLight};
		}
	}
	@media (min-width: 768px) {
		flex-direction: row;
		padding: 86px 66px;
		height: 50vh;
	}
`;

const StyledCounterWrapper = styled.div`
	display: flex;
	flex-direction: row;
	height: 67px;
	justify-content: space-between;
	margin-bottom: 23px;
	position: relative;
	width: 67px;
	&:after {
		background-color: ${({ theme }) => theme.colors.black};
		content: '';
		height: 1px;
		left: calc(50% - 23px / 2);
		position: absolute;
		top: calc(50% - 4px);
		transform: rotate(-45deg);
		width: 23px;
	}
	@media (min-width: 768px) {
		margin-right: 106px;
	}
`;

const StyledNumberWrapper = styled.div`
	color: ${({ theme }) => theme.colors.black};
	display: flex;
	flex-direction: column;
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 20px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	height: 100%;
	justify-content: flex-end;
	line-height: 30px;
	width: 50%;
	&.current {
		justify-content: flex-start;
	}
`;

const StyledHeaderWrapper = styled.div`
	color: ${({ theme }) => theme.colors.black};
	font-family: ${({ theme }) => theme.fontFamily.primary};
	font-size: 36px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	line-height: 45px;
	margin-bottom: 30px;
	@media (min-width: 768px) {
		font-size: 64px;
		line-height: 72px;
		margin-bottom: 40px;
	}
`;

const StyledDescriptionWrapper = styled.div`
	color: ${({ theme }) => theme.colors.black};
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 16px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 26px;
	max-width: 444px;
`;

const StyledImageWrapper = styled.img`
	height: 50vh;
	object-fit: cover;
	width: 100%;
`;

const VerticalSliderStaticMobile = ({ data }) => {
	const nohoSmartSliderSlides = data?.nohoSmartVerticalSliderModule || [];
	const imagesArray = [NohoServices, NohoApp];

	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger);
		const textElements = document.querySelectorAll('.slide-text-wrapper-mobile');
		textElements.forEach((element, key) => {
			if (element.children) {
				gsap.fromTo(
					element.children,
					{ y: '200', opacity: 0, duration: 0.4, ease: 'power4.easeOut' },
					{
						y: 0,
						opacity: 1,
						stagger: 0.2,
						ease: 'power4.easeOut',
						duration: 1,
						scrollTrigger: {
							id: `ns-slider-mobile-${key}`,
							trigger: `#image-slider-${key}`,
							start: 'top 30%',
							end: 'bottom 50%',
							toggleActions: 'restart play complete reverse',
						},
					},
				);
			}
		});

		return () => {
			const singleSlide0ST = ScrollTrigger.getById('ns-slider-mobile-0');
			const singleSlide1ST = ScrollTrigger.getById('ns-slider-mobile-1');
			singleSlide0ST.kill();
			singleSlide1ST.kill();
		};
	});

	return (
		<PageContainer className="full-width" id="scrollTo">
			<StyledSliderContainer className="slides" id="noho-smart-slider-wrapper-mobile">
				{nohoSmartSliderSlides.map((item, key) => {
					const header = item?.nohoSmartVerticalSliderHeader || '';
					const description = item?.nohoSmartVerticalSliderDescription || '';
					return (
						<StyledSingleSlideWrapper key={uuid()}>
							<StyledImageWrapper
								id={`image-slider-${key}`}
								src={imagesArray[key]}
								className="noho-smart-slider-image-mobile"
							/>
							<StyledTextPartWrapper
								id={`text-wrapper-${key}`}
								className={`slide-text-wrapper-mobile-${key} slide-text-wrapper-mobile`}
							>
								<StyledCounterWrapper>
									<StyledNumberWrapper className="current">0{key + 1}</StyledNumberWrapper>
									<StyledNumberWrapper>0{nohoSmartSliderSlides.length}</StyledNumberWrapper>
								</StyledCounterWrapper>
								<div style={{ width: '100%' }}>
									<StyledHeaderWrapper
										dangerouslySetInnerHTML={GetHtmlFromRedactorField(header)}
										className={`slide-header slide-header-${key}`}
									/>
									<StyledDescriptionWrapper
										dangerouslySetInnerHTML={GetHtmlFromRedactorField(description)}
										className={`slide-description-${key}`}
									/>
								</div>
							</StyledTextPartWrapper>
						</StyledSingleSlideWrapper>
					);
				})}
			</StyledSliderContainer>
		</PageContainer>
	);
};

export default VerticalSliderStaticMobile;
