import * as React from 'react';

const NohoHome = ({ colorCircle, ...props }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={146} height={146} {...props}>
		<circle className="circle" cx={73} cy={73} r={73} fill={colorCircle} />
		<g>
			<svg xmlns="http://www.w3.org/2000/svg" width={146} height={146} viewBox="-50 -50 146 146" {...props}>
				<g data-name="noho-Smart home" fill={props.color}>
					<path
						data-name="Path 117"
						d="m43.877 19.701 3.656 3.022 1.9-2.294-5.55-4.588V2.734h-8.787v5.845L24.717 0 0 20.429l1.9 2.294L6 19.335V43.51H.05v2.975h49.327V43.51h-5.5Zm-2.976 23.807H8.973V16.873L24.714 3.86l16.187 13.382Zm0-30.127-2.833-2.343V5.71h2.833Z"
					/>
					<path
						data-name="Path 118"
						d="M16.403 16.795V38.17a2.448 2.448 0 0 0 2.447 2.447h10.942a2.448 2.448 0 0 0 2.447-2.447V16.795a2.45 2.45 0 0 0-2.447-2.447H18.85a2.453 2.453 0 0 0-2.447 2.447Zm2.975.531h9.885v14.52h-9.885Zm0 17.5h3.445a1.48 1.48 0 0 0 2.961 0h3.482v2.82h-9.885v-2.82Z"
					/>
				</g>
			</svg>
		</g>
	</svg>
);

export default NohoHome;
