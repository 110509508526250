import * as React from 'react';

const NohoEnter = ({ colorCircle, ...props }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={102} height={102} {...props}>
		<circle className="circle" id="texture" cx="51" cy="51" r="51" fill={colorCircle} />
		<g>
			<svg
				data-name="noho- Wejs\u0301cie do budynku bez koniecznos\u0301ci uz\u0307ycia klucza:keyless"
				xmlns="http://www.w3.org/2000/svg"
				width={102}
				height={102}
				viewBox="-30 -30 102 102"
				{...props}
			>
				<g data-name="Group 36">
					<path
						data-name="Path 131"
						d="M40.165 37.813H.042v-2.42h4.834V15.727l-3.334 2.756L0 16.617 20.1 0l8.45 6.979V2.226h7.145v10.661l4.514 3.732-1.542 1.866-2.974-2.458v19.366h4.474v2.42Zm-32.867-2.42h25.973V14.025L20.104 3.14 7.299 13.725ZM30.967 8.981l2.3 1.906v-6.24h-2.3ZM20.286 33.07a5.43 5.43 0 0 1-5.424-5.422v-7.34a5.425 5.425 0 0 1 10.849 0v7.985H23.29v-7.985a3.005 3.005 0 0 0-6.009 0v7.342a3.006 3.006 0 0 0 3 3v2.42Zm-7.563-2.416h-2.42V20.49a10.014 10.014 0 0 1 10-10v2.42a7.591 7.591 0 0 0-7.583 7.583Zm8.773-4.732h-2.42V20.03h2.42Zm8.813-.028h-2.42V20.49a7.543 7.543 0 0 0-3.6-6.457l1.269-2.063a9.947 9.947 0 0 1 4.75 8.515Z"
						fill={props.color}
					/>
				</g>
			</svg>
		</g>
	</svg>
);

export default NohoEnter;
