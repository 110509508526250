import React, { useCallback, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import NohoBike from './NohoBike';
import NohoHome from './NohoHome';
import NohoApp from './NohoApp';
import NohoScooter from './NohoScooter';
import NohoEnter from './NohoEnter';

const colorChange = keyframes`
  0%{fill:rgba(18, 18, 18, 1)}
  100%{fill: rgba(247, 245, 243, 0.7)}
`;

const StyledBikeIcon = styled.div`
	position: absolute;
	top: 120px;
	right: 36.067vw;
	@media (min-width: 768px) and (max-width: 1024px) {
		right: 68vw;
	}
	.active {
		.circle {
			// animation: ${colorChange} 1s linear;
		}
	}
`;
const StyledHomeIcon = styled.div`
	position: absolute;
	top: 75px;
	right: 10.688vw;
	@media (min-width: 768px) and (max-width: 1024px) {
		right: 8.496vw;
	}
	.active {
		.circle {
			// animation: ${colorChange} 1s linear;
		}
	}
`;

const StyledAppIcon = styled.div`
	position: absolute;
	top: 318px;
	right: 19.327vw;
	@media (min-width: 768px) and (max-width: 1024px) {
		right: 26vw;
		top: 280px;
	}
	.active {
		.circle {
			// animation: ${colorChange} 1s linear;
		}
	}
`;

const StyledEnterIcon = styled.div`
	position: absolute;
	right: 5.857vw;
	top: 540px;
	@media (min-width: 768px) and (max-width: 1024px) {
		right: 5.664vw;
		top: 430px;
	}
	.active {
		.circle {
			// animation: ${colorChange} 1s linear;
		}
	}
`;

const StyledScooterIcon = styled.div`
	position: absolute;
	top: 579px;
	right: 29.502vw;
	@media (min-width: 768px) and (max-width: 1024px) {
		display: none;
	}
	.active {
		.circle {
			// animation: ${colorChange} 1s linear;
		}
	}
`;
const StyledPulse = styled.div`
	border: 0.5px solid black;
	border-radius: 100%;
	height: 100%;
	position: absolute;
	top: -1px;
	width: 100%;

	&.active {
		-webkit-animation: pulse 6s linear infinite;
		animation: pulse 6s linear infinite;
		border: 0.5px solid rgba(247, 245, 243, 0.5);
		&.scooter {
			&.pulse-one {
				animation-delay: 3s;
				opacity: 0;
			}
			&.pulse-two {
				animation-delay: 3.75s;
				opacity: 0;
			}

			&.pulse-three {
				animation-delay: 4.25s;
				opacity: 0;
			}

			&.pulse-four {
				animation-delay: 5s;
				opacity: 0;
			}
		}
		&.bike {
			&.pulse-one {
				animation-delay: 2s;
				opacity: 0;
			}
			&.pulse-two {
				animation-delay: 2.75s;
				opacity: 0;
			}

			&.pulse-three {
				animation-delay: 3.25s;
				opacity: 0;
			}

			&.pulse-four {
				animation-delay: 4s;
				opacity: 0;
			}
		}
		&.app {
			&.pulse-one {
				animation-delay: 7s;
				opacity: 0;
			}
			&.pulse-two {
				animation-delay: 7.75s;
				opacity: 0;
			}

			&.pulse-three {
				animation-delay: 8.25s;
				opacity: 0;
			}

			&.pulse-four {
				animation-delay: 9s;
				opacity: 0;
			}
		}
		&.enter {
			&.pulse-one {
				animation-delay: 5s;
				opacity: 0;
			}
			&.pulse-two {
				animation-delay: 5.75s;
				opacity: 0;
			}

			&.pulse-three {
				animation-delay: 6.25s;
				opacity: 0;
			}

			&.pulse-four {
				animation-delay: 7s;
				opacity: 0;
			}
		}
	}
	@keyframes pulse {
		0% {
			opacity: 0;
			transform: scale(1);
		}

		25% {
			opacity: 0.9;
			transform: scale(1.5);
		}

		50% {
			opacity: 0.1;
			transform: scale(2);
		}

		75% {
			opacity: 0;
			border-color: transparent;
			display: none;
			transform: scale(2);
		}
		100% {
			opacity: 0;
			border-color: transparent;
			display: none;
			transform: scale(2.25);
		}
	}

	&.scooter {
		height: 100px;
		width: 100px;
	}
	&.app {
		height: 98px;
		width: 98px;
	}
	&.enter {
		height: 102px;
		width: 102px;
	}
	&.pulse-one {
		animation-delay: 0s;
		opacity: 0;
	}

	&.pulse-two {
		animation-delay: 0.75s;
		opacity: 0;
	}

	&.pulse-three {
		animation-delay: 1.25s;
		opacity: 0;
	}

	&.pulse-four {
		animation-delay: 2s;
		opacity: 0;
	}
`;
const TemplateIcons = () => {
	const [anim, setCount] = useState(false);
	useEffect(() => {
		setCount(true);
	});

	return (
		<>
			<StyledBikeIcon>
				<NohoBike
					color={anim ? 'black' : 'rgba(247, 245, 243, 0.7)'}
					colorCircle={anim ? 'rgba(247, 245, 243, 0.7)' : 'black'}
					className={anim ? 'active' : ''}
				/>
				<StyledPulse className={anim ? `active  pulse-one bike` : `pulse-one bike`} />
				<StyledPulse className={anim ? `active  pulse-two bike` : `pulse-two bike`} />
				<StyledPulse className={anim ? `active  pulse-three bike` : `pulse-three bike`} />
				<StyledPulse className={anim ? `active  pulse-four bike` : `pulse-four bike`} />
			</StyledBikeIcon>
			<StyledHomeIcon>
				<NohoHome
					color={anim ? 'black' : 'rgba(247, 245, 243, 0.7)'}
					colorCircle={anim ? 'rgba(247, 245, 243, 0.7)' : 'black'}
					className={anim ? 'active' : ''}
				/>
				<StyledPulse className={anim ? `active  pulse-one home` : `pulse-one home`} />
				<StyledPulse className={anim ? `active  pulse-two home` : `pulse-two home`} />
				<StyledPulse className={anim ? `active  pulse-three home` : `pulse-three home`} />
				<StyledPulse className={anim ? `active  pulse-four home` : `pulse-four home`} />
			</StyledHomeIcon>
			<StyledAppIcon>
				<NohoApp
					color={anim ? 'black' : 'rgba(247, 245, 243, 0.7)'}
					colorCircle={anim ? 'rgba(247, 245, 243, 0.7)' : 'black'}
					className={anim ? 'active' : ''}
				/>
				<StyledPulse className={anim ? `active  pulse-one app` : `pulse-one app`} />
				<StyledPulse className={anim ? `active  pulse-two app` : `pulse-two app`} />
				<StyledPulse className={anim ? `active  pulse-three app` : `pulse-three app`} />
				<StyledPulse className={anim ? `active  pulse-four app` : `pulse-four app`} />
			</StyledAppIcon>
			<StyledEnterIcon>
				<NohoEnter
					color={anim ? 'black' : 'rgba(247, 245, 243, 0.7)'}
					colorCircle={anim ? 'rgba(247, 245, 243, 0.7)' : 'black'}
					className={anim ? 'active' : ''}
				/>
				<StyledPulse className={anim ? `active  pulse-one enter` : `pulse-one enter`} />
				<StyledPulse className={anim ? `active  pulse-two enter` : `pulse-two enter`} />
				<StyledPulse className={anim ? `active  pulse-three enter` : `pulse-three enter`} />
				<StyledPulse className={anim ? `active  pulse-four enter` : `pulse-four enter`} />
			</StyledEnterIcon>
			<StyledScooterIcon>
				<NohoScooter
					color={anim ? 'black' : 'rgba(247, 245, 243, 0.7)'}
					colorCircle={anim ? 'rgba(247, 245, 243, 0.7)' : 'black'}
					className={anim ? 'active' : ''}
				/>
				<StyledPulse className={anim ? `active  pulse-one scooter` : `pulse-one scooter`} />
				<StyledPulse className={anim ? `active  pulse-two scooter` : `pulse-two scooter`} />
				<StyledPulse className={anim ? `active  pulse-three scooter` : `pulse-three scooter`} />
				<StyledPulse className={anim ? `active  pulse-four scooter` : `pulse-four scooter`} />
			</StyledScooterIcon>
		</>
	);
};
export default TemplateIcons;
