import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import uuid from 'react-uuid';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useSelector } from 'react-redux';
import PageContainer from '../../common/PageContainer';
import GetHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import NohoApp from '../../assets/image/nohoSmart/verticalSlider/noho_app.webp';
import NohoServices from '../../assets/image/nohoSmart/verticalSlider/noho_service.webp';

const StyledSliderContainer = styled.div`
	display: block;
	height: 100vh;
	position: relative;
	width: 100%;
`;

const StyledSingleSlideWrapper = styled.div`
	display: flex;
	flex-direction: row;
	height: 100vh;
	justify-content: space-between;
	position: relative;
	width: 100%;
	&.hidden {
		display: none;
	}
`;

const StyledTextPartWrapper = styled.div`
	background-color: #dedede;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	padding-left: 25px;
	width: 50%;
	@media (min-width: 768px) {
		padding-left: 65px;
	}
	@media (min-width: 1366px) {
		padding-left: 140px;
	}
	@media (min-width: 1920px) {
		padding-left: calc((100vw - 1510px) / 2);
	}
`;

const StyledCounterWrapper = styled.div`
	//visibility: hidden;
	display: flex;
	flex-direction: row;
	height: 67px;
	justify-content: space-between;
	margin-bottom: 33px;
	position: relative;
	width: 67px;
	&:after {
		background-color: ${({ theme }) => theme.colors.black};
		content: '';
		height: 1px;
		left: calc(50% - 23px / 2);
		position: absolute;
		top: calc(50% - 4px);
		transform: rotate(-45deg);
		width: 23px;
	}
`;

const StyledNumberWrapper = styled.div`
	color: ${({ theme }) => theme.colors.black};
	display: flex;
	flex-direction: column;
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 20px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	height: 100%;
	justify-content: flex-end;
	line-height: 30px;
	width: 50%;
	&.current {
		justify-content: flex-start;
	}
`;

const StyledHeaderWrapper = styled.div`
	color: ${({ theme }) => theme.colors.black};
	font-family: ${({ theme }) => theme.fontFamily.primary};
	font-size: 64px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 70px;
	margin-bottom: 55px;
`;

const StyledDescriptionWrapper = styled.div`
	color: ${({ theme }) => theme.colors.black};
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 16px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 26px;
	margin-bottom: 55px;
	max-width: 444px;
`;

const StyledImageWrapper = styled.div`
	background-image: url(${({ bgImg }) => bgImg});
	background-position: center;
	background-size: cover;
	height: 100vh;
	position: absolute;
	right: 0;
	top: 0;
	transition: background 0.3s ease-in-out;
	width: 50%;
	&.active {
		opacity: 1;
		transition: opacity 0.3s ease-in-out;
	}
	&.hidden {
		opacity: 0;
		transition: opacity 0.9s ease-in-out;
	}
`;

const VerticalSliderNohoSmart = ({ data }) => {
	const nohoSliderSlides = data?.nohoSmartVerticalSliderModule || [];
	const [currentSlide, setCurrentSlide] = useState(1);
	const initialHeading = nohoSliderSlides?.[0]?.nohoSmartVerticalSliderHeader || '';
	const initialDescription = nohoSliderSlides?.[0]?.nohoSmartVerticalSliderDescription || '';
	const [heading, setHeading] = useState(initialHeading);
	const [description, setDescription] = useState(initialDescription);
	const isDropdownMenuOpen = useSelector((state) => state.isDropdownMenuOpen.isDropdownMenuOpen);
	const imagesArray = [NohoServices, NohoApp];
	const handleStaggerTextAnimation = (key) => {
		const target = document.querySelectorAll('.slide-text-wrapper');
		const elements = target?.[0]?.children || null;
		if (elements && key === 1) {
			gsap.fromTo(
				target[0].children,
				{
					y: '+=60',
					autoAlpha: 0,
				},
				{
					stagger: 0.2,
					y: 0,
					autoAlpha: 1,
					duration: 0.6,
					ease: 'power4.easeOut',
				},
			);
		}
	};
	useEffect(() => {
		ScrollTrigger.create({
			id: 'noho-smart-vertical-slider',
			trigger: '#slider-wrapper',
			pin: true,
			start: 'top top',
			scrub: 1,
			end: 'bottom -=100%',
		});

		const slides = document.querySelectorAll('.vertical-slide');
		slides.forEach((slide, key) => {
			ScrollTrigger.create({
				id: `noho-smart-single-slide-${key}`,
				trigger: slide,
				start: 'top top',
				end: 'bottom bottom',
				markers: false,
				anticipatePin: true,
				onEnter: () => {
					if (key === 1) {
						setCurrentSlide(2);
						setHeading(nohoSliderSlides[1].nohoSmartVerticalSliderHeader);
						setDescription(nohoSliderSlides[1].nohoSmartVerticalSliderDescription);
						gsap.to('.slide-text-wrapper', {
							backgroundColor: 'rgb(236,228,217)',
							transition: 'all 1s ease-in-out',
							duration: 0.6,
							ease: 'power1.easeOut',
						});
					}
				},
				onEnterBack: () => {
					if (key === 1) {
						setCurrentSlide(1);
						setHeading(nohoSliderSlides[0].nohoSmartVerticalSliderHeader);
						setDescription(nohoSliderSlides[0].nohoSmartVerticalSliderDescription);
					}
					gsap.to('.slide-text-wrapper', {
						backgroundColor: '#DEDEDE',
						duration: 0.5,
						ease: 'easeInOut',
					});
				},
				onLeave: () => {
					handleStaggerTextAnimation(key);
				},
				onLeaveBack: () => {
					handleStaggerTextAnimation(key);
				},
			});
		});
		return () => {
			const nohoSmartVerticalSliderST = ScrollTrigger.getById('noho-smart-vertical-slider');
			const singleSlide0ST = ScrollTrigger.getById('noho-smart-single-slide-0');
			const singleSlide1ST = ScrollTrigger.getById('noho-smart-single-slide-1');
			nohoSmartVerticalSliderST.kill();
			singleSlide0ST.kill();
			singleSlide1ST.kill();
		};
	}, [isDropdownMenuOpen]);
	return (
		<PageContainer className="full-width" id="scrollTo">
			<StyledSliderContainer className="slides anim" id="slider-wrapper">
				<StyledImageWrapper
					bgImg={imagesArray[0]}
					className={currentSlide === 1 ? 'active slide-image-wrapper' : 'hidden slide-image-wrapper'}
				/>
				<StyledImageWrapper
					bgImg={imagesArray[1]}
					className={currentSlide === 2 ? 'active slide-image-wrapper' : 'hidden slide-image-wrapper'}
				/>
				{nohoSliderSlides.map((item, key) => (
					<StyledSingleSlideWrapper className={key === 1 ? 'hidden vertical-slide' : 'vertical-slide'} key={uuid()}>
						<StyledTextPartWrapper className="slide-text-wrapper">
							<StyledCounterWrapper>
								<StyledNumberWrapper className="current">0{currentSlide}</StyledNumberWrapper>
								<StyledNumberWrapper>0{nohoSliderSlides.length}</StyledNumberWrapper>
							</StyledCounterWrapper>
							<StyledHeaderWrapper
								dangerouslySetInnerHTML={GetHtmlFromRedactorField(heading)}
								className={`slide-header-${key}`}
							/>
							<StyledDescriptionWrapper
								dangerouslySetInnerHTML={GetHtmlFromRedactorField(description)}
								className={`slide-description-${key}`}
							/>
						</StyledTextPartWrapper>
					</StyledSingleSlideWrapper>
				))}
			</StyledSliderContainer>
		</PageContainer>
	);
};

export default VerticalSliderNohoSmart;
