import * as React from 'react';

const NohoApp = ({ colorCircle, ...props }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={98} height={98} {...props}>
		<circle className="circle" id="texture" cx="49" cy="49" r="49" fill={colorCircle} />

		<g>
			<svg xmlns="http://www.w3.org/2000/svg" width={98} height={98} viewBox="-36 -33 98 98" {...props}>
				<g data-name="noho-Aplikacja mobilna dla mieszkan\u0301co\u0301w" fill={props.color}>
					<path data-name="Path 119" d="M9.778 31.343a1.1 1.1 0 1 0-1.1-1.1 1.1 1.1 0 0 0 1.1 1.1Z" />
					<path data-name="Rectangle 55" d="M4.356 8.583h6.508v2.218H4.356z" />
					<path data-name="Rectangle 56" d="M4.356 4.218h10.845v2.218H4.356z" />
					<path
						data-name="Path 120"
						d="M24.887 4.211a12.059 12.059 0 0 0-5.335 1.239V1.824A1.826 1.826 0 0 0 17.728 0h-15.9A1.825 1.825 0 0 0 0 1.824v31.041a1.825 1.825 0 0 0 1.824 1.824H17.73a1.825 1.825 0 0 0 1.824-1.824v-5.639a12.126 12.126 0 1 0 5.333-23.015Zm-7.553 28.258H2.218v-4.327h15.116Zm0-6.544H2.218V2.218h15.116V6.86a12.152 12.152 0 0 0-4.1 6.114H4.356v2.218h8.458a12.05 12.05 0 0 0-.017 2.092L6.52 23.816h8.821a12.193 12.193 0 0 0 1.993 2Zm7.553.324a9.933 9.933 0 0 1-8.087-4.183l-.332-.467h-4.741l3.389-3.529-.064-.522a9.908 9.908 0 1 1 9.835 8.7Z"
					/>
					<path
						data-name="Path 121"
						d="M30.314 13.138V9.89h-2.218v1.415l-3.137-2.592-8.015 6.62 1.413 1.71 1.188-.981v5.591h10.769v-5.637l1.247 1.03 1.413-1.71Zm-2.218 6.3h-6.332v-5.205l3.2-2.642 3.137 2.592Z"
					/>
				</g>
			</svg>
		</g>
	</svg>
);

export default NohoApp;
