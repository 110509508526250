import * as React from 'react';

const NohoBike = ({ colorCircle, ...props }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={146} height={146} {...props}>
		<circle className="circle" cx={73} cy={73} r={73} fill={colorCircle} />
		<g>
			<svg xmlns="http://www.w3.org/2000/svg" width={146} height={146} viewBox="-50 -50 146 146" {...props}>
				<g data-name="noho- Rowerownia z moz\u0307liwos\u0301cia\u0328 \u0142adowania rowero\u0301w elektrycznych">
					<g data-name="Group 26">
						<g data-name="Group 25">
							<path
								data-name="Path 115"
								d="M8.711 17.391H5.744v-5.678H1.855l-.414-.8A12.952 12.952 0 0 1 .002 5.86V2.844h2.69V0h2.967v2.844h2.984V0h2.967v2.844h2.847V5.86a12.688 12.688 0 0 1-1.486 5.078l-.419.785H8.71v5.674Zm-5-8.65 7.02.01a8.328 8.328 0 0 0 .765-2.893v-.049H2.969v.049a8.517 8.517 0 0 0 .74 2.883Z"
								fill={props.color}
							/>
						</g>
					</g>
					<g data-name="Group 27">
						<path
							data-name="Path 116"
							d="M49.31 23.303h-11.6v2.844a10.186 10.186 0 0 0-6.01 2.976l-2.589-.951 3.879-10.747h-6.816v2.967h2.589l-4.1 11.365a17.475 17.475 0 0 1-1.287-1.912 52.835 52.835 0 0 1-3.791-8.751l-1.275-3.8 1.1-3.035 6.79-2.605-1.068-2.771-8.062 3.094-5.134 14.239a10.214 10.214 0 1 0 2.82.922l1.93-5.348.091.27a55.655 55.655 0 0 0 4.038 9.288 13.62 13.62 0 0 0 4.362 4.889 10.683 10.683 0 0 0 3.678 1.368 10.209 10.209 0 1 0 12.16-11.333h8.295ZM17.453 36.276a7.243 7.243 0 1 1-7.243-7.243c.235 0 .468.012.7.034l-2.739 7.6 2.79 1.008 2.783-7.716a7.242 7.242 0 0 1 3.709 6.317Zm11.455-1.693a7.767 7.767 0 0 1-1.866-.7L28.1 30.96l1.792.66a10.214 10.214 0 0 0-.984 2.963Zm2.967.26a7.189 7.189 0 0 1 .831-2.187l4.786 1.758v.429Zm14.344 1.432A7.243 7.243 0 0 1 31.9 37.811h8.562v-5.463l-5.568-2.047a7.239 7.239 0 0 1 11.328 5.975Z"
							fill={props.color}
						/>
					</g>
				</g>
			</svg>
		</g>
	</svg>
);

export default NohoBike;
