import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ScrollIndicator from '../components/ScrollIndicator/ScrollIndicator';
import GetHtmlFromRedactorField from '../utils/getHtmlFromRedactorField';
import { setScaleIntroAnimation } from '../state/scaleIntroAnim';
import { setIntroStartModule } from '../state/introStartModule';
import TemplateIcons from '../components/nohoSmart/TemplateIcons';
import { setIntroLoadedOnce } from '../state/introLoadedOnce';
import { setMenuColor } from '../state/menuColor';
import VerticalSliderNohoSmartContainer from '../components/verticalSlider/VerticalSliderNohoSmartContainer';
import { setIsDropdownMenu } from '../state/isDropdownMenuSlice';
import Seo from '../components/seo/Seo';

const StyledView = styled.div`
	//height: 100vh;
	align-items: flex-start;
	background-color: ${({ theme }) => theme.colors.white};
	display: flex;
	flex-direction: column;
	height: calc(var(--vh, 1vh) * 100);
	justify-content: center;
	overflow: hidden;
	padding: 40px 80px;
	position: relative;
	text-align: center;

	width: 100%;
	&.last {
		background-color: ${({ theme }) => theme.colors.green};
		background-position: left;
	}
	@media (min-width: 768px) and (max-width: 1024px) {
		padding: 32px 64px;
	}
	@media (min-width: 376px) and (max-width: 767px) {
		height: calc(var(--vh, 1vh) * 100);
		padding: 0 24px;
	}
	@media (max-width: 375px) {
		height: calc(var(--vh, 1vh) * 100);
		padding: 0 24px;
		align-items: center;
	}
`;
const StyledText = styled.div`
	color: ${({ theme }) => theme.colors.black};
	font-family: ${({ theme }) => theme.fontFamily.primary};
	font-size: 98px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	left: 50%;
	line-height: 120px;
	max-width: 80%;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	@media (min-width: 768px) and (max-width: 1366px) {
		font-size: 80px;
		line-height: 100px;
		max-width: 95%;
		width: 100%;
	}
	@media (min-width: 376px) and (max-width: 767px) {
		font-size: 44px;
		line-height: 64px;
		max-width: 100%;
		padding: 0 24px;
	}
	@media (max-width: 375px) {
		font-size: 38px;
		line-height: 58px;
	}
`;

const StyledColumnText = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-end;
	position: relative;
	text-align: left;
	width: 65%;
	@media (max-width: 1024px) {
		width: 100%;
		height: 100%;
		align-items: flex-end;
		justify-content: flex-start;
	}
`;
const StyledHeader = styled.div`
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fontFamily.primary};
	font-size: 100px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	height: 50%;
	line-height: 100px;
	position: absolute;
	top: 20%;
	visibility: hidden;
	width: 100%;
	@media (min-width: 768px) and (max-width: 1024px) {
		height: 100%;
		font-size: 80px;
		top: 35%;
		line-height: 95px;
	}
	@media (min-width: 421px) and (max-width: 767px) {
		font-size: 36px;
		line-height: 40px;
		height: 100%;
		top: 15%;
	}
	@media (max-width: 420px) {
		font-size: 36px;
		line-height: 40px;
		height: 100%;
		top: 5%;
	}
`;
const StyledDesc = styled.div`
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 16px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	height: 50%;
	line-height: 26px;
	max-width: 100%;
	position: absolute;
	top: 50%;
	visibility: hidden;
	width: 100%;
	@media (min-width: 1025px) {
		max-width: 85%;
	}
	@media (min-width: 768px) and (max-width: 1024px) {
		top: 65%;
		width: 85%;
		left: 0;
	}
	@media (min-width: 421px) and (max-width: 767px) {
		height: 100%;
		top: 30%;
	}
	@media (max-width: 420px) {
		font-size: 14px;
		position: absolute;
		line-height: 24px;
		height: 100%;
		top: 20%;
	}
`;

const StyledIndicator = styled.div`
	bottom: 0;
	left: 50%;
	position: absolute;
	transform: translate(-50%);
`;

const NohoSmartPage = ({ data, pageContext }) => {
	const pageData = data?.nohoSmartData?.nodes?.[0] || [];
	const global = pageContext?.globals?.[0] || [];
	const textIndicator = global?.textIndicator || '';
	const [windowWidth, setWindowWidth] = useState(0);
	const seoGlobal = pageContext?.seoGlobal?.data?.datoCmsSite?.globalSeo || [];
	const seoData = pageData?.seoSettings || '';
	const dispatch = useDispatch();

	useEffect(() => {
		if (typeof window !== 'undefined') {
			let width;
			const vh = window.innerHeight * 0.01;
			width = window?.innerWidth || 0;
			setWindowWidth(width);
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		}
	});
	useEffect(() => {
		const headerDesc = document.querySelector('.text-desc');
		const textDesc = document.querySelector('.text-desc-2');
		const timeline = gsap.timeline();

		ScrollTrigger.create({
			id: 'noho-smart-desc',
			trigger: '#section-pulse',
			start: 'top-=50%',
			onEnterBack: () => null,
			onEnter: () => {
				timeline
					.fromTo(
						headerDesc,
						{
							autoAlpha: 0,
							ease: 'power4.easeOut',
							y: 200,
							duration: 0.4,
						},
						{
							autoAlpha: 1,
							ease: 'power4.easeOut',
							y: 0,
							duration: 1,
						},
					)
					.fromTo(
						textDesc,
						{
							autoAlpha: 0,
							ease: 'power4.easeOut',
							y: 150,
							duration: 0.4,
						},
						{
							autoAlpha: 1,
							ease: 'power4.easeOut',
							y: 0,
							duration: 1,
						},
					);
			},
		});
		return () => {
			const nohoSmartPulseTextST = ScrollTrigger.getById('noho-smart-desc');
			nohoSmartPulseTextST.kill();
		};
	}, []);
	useEffect(() => {
		dispatch(setScaleIntroAnimation(true));
		dispatch(setIntroStartModule(false));
		dispatch(setMenuColor(true));
		dispatch(setIntroLoadedOnce(true));
		dispatch(setIsDropdownMenu(true));
		if (typeof window !== 'undefined') {
			const width = window?.innerWidth || 0;
			if (width < 1024) {
				dispatch(setIsDropdownMenu(true));
			}
		}
	}, []);
	return (
		<>
			<Seo seoGlobal={seoGlobal} seoData={seoData} />
			<StyledView className="section">
				<StyledText data-animation-type="text-second" className="anim text-second">
					{pageData?.text}
				</StyledText>
				<StyledIndicator id="indicatorBtn">
					<ScrollIndicator text={textIndicator} pageContext={pageContext} />
				</StyledIndicator>
			</StyledView>
			<VerticalSliderNohoSmartContainer data={pageData} pageContext={pageContext} />
			<StyledView className="section last" id="section-pulse">
				<StyledColumnText>
					<StyledHeader dangerouslySetInnerHTML={GetHtmlFromRedactorField(pageData?.header)} className="text-desc" />
					<StyledDesc dangerouslySetInnerHTML={GetHtmlFromRedactorField(pageData?.description)} className="text-desc-2" />
				</StyledColumnText>
				{windowWidth >= 768 && <TemplateIcons />}
			</StyledView>
		</>
	);
};

export default NohoSmartPage;

export const query = graphql`
	query ($locale: String) {
		nohoSmartData: allDatoCmsNohoSmartPage(filter: { locale: { eq: $locale } }) {
			nodes {
				seoSettings {
					title
					description
					twitterCard
					image {
						url
					}
				}
				nohoSmartVerticalSliderModule {
					nohoSmartVerticalSliderHeader
					nohoSmartVerticalSliderDescription
				}
				text
				header
				description
			}
		}
	}
`;
