import * as React from 'react';

const NohoScooter = ({ colorCircle, ...props }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} {...props}>
		<circle className="circle" id="texture" cx="50" cy="50" r="50" fill={colorCircle} />
		<g>
			<svg
				data-name="noho-Przechowalnia hulajno\u0301g elektrycznych z moz\u0307liwos\u0301cia\u0328 ich \u0142adowania"
				xmlns="http://www.w3.org/2000/svg"
				width={100}
				height={100}
				viewBox="-27 -25 100 100"
				{...props}
			>
				<path
					data-name="Path 170"
					d="M5.508 16.683h2.846V11.24h3.685l.4-.753a12.173 12.173 0 0 0 1.427-4.87V2.728H11.14V0H8.294v2.728H5.43V0H2.584v2.728H0V5.62a12.423 12.423 0 0 0 1.38 4.842l.4.767 3.73.007Zm-1.952-8.3a8.168 8.168 0 0 1-.71-2.766V5.57h8.174v.047a7.988 7.988 0 0 1-.734 2.775Z"
					fill={props.color}
				/>
				<path
					data-name="Path 171"
					d="M44.666 37.473a5.607 5.607 0 0 0-10.615-.569 8.009 8.009 0 0 1 7.789-6.321v-2.846a10.854 10.854 0 0 0-10.757 9.734h-8.775a4.646 4.646 0 0 1-2.211-.626 6.2 6.2 0 0 1-1.881-2.192 31.807 31.807 0 0 1-2.281-5.27l-1.185-3.777 4.231-11.934 6.512-2.5-1.021-2.65-7.737 2.968-7.765 21.9q-.233-.021-.473-.021a5.615 5.615 0 1 0 3.154.974l1.514-4.268s1.783 4.637 2.6 6.027a8.807 8.807 0 0 0 2.853 3.179 7.466 7.466 0 0 0 3.593 1.042h11.61a5.61 5.61 0 0 0 10.893 0h2.784v-2.847h-2.832ZM8.5 41.739a2.763 2.763 0 0 1-.546-5.472l-.793 2.239 2.681.95.793-2.234A2.762 2.762 0 0 1 8.5 41.74Zm30.765-5.526a2.755 2.755 0 0 1 2.316 1.263h-4.636a2.763 2.763 0 0 1 2.319-1.263Zm0 5.526a2.762 2.762 0 0 1-2.413-1.42h4.826a2.765 2.765 0 0 1-2.414 1.42Z"
					fill={props.color}
				/>
			</svg>
		</g>
	</svg>
);

export default NohoScooter;
