import React from 'react';
import { isMobile } from 'react-device-detect';
import VerticalSliderNohoSmart from './VerticalSliderNohoSmart';
import VerticalSliderStaticMobile from './VerticalSliderStaticMobile';

const VerticalSliderNohoSmartContainer = ({ data, pageContext }) => (
	<>
		{!isMobile && <VerticalSliderNohoSmart data={data} pageContext={pageContext} />}
		{isMobile && <VerticalSliderStaticMobile data={data} pageContext={pageContext} />}
	</>
);

export default VerticalSliderNohoSmartContainer;
